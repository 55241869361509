const initialState = {
  loaderstatus: false,
  loaderstatus1: false,
  homedata: {},
  cartdata: {},
  cartdisplay: [],
  deataidata: [],
  relateddata: [],
  populardata: [],
  navdata: [],
  categoryOpen: 'level1',
  hasmore: true,
  sidebar: false,
  dochomedata:true,
  langdata: [],
  slectedkeys: '',
  faqs: [],
  footerdata: [],
  homecarouseldata: [],
  subfilterdata: [],
  subscriptiondata: [],
  staticpage: [],
  page: 1,
  vendor_token: '',
  categoryslug: '',
  currentStep: 1,
  firstStepForm: {
    registeremail: "",
    password: "",
  },
  formData:{},
  verificationStatus:{},
  setotp_msz:"",
  otp_status: false,
  checkStatus: 0,
  razzer:[],
  
  
  subcategoryslug: '',
  vendorType: [],
  closeWizard: false,
  htmldata: [],
  selectedcatdata: '',
  Openmodal: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'setdochomedata':
      return {
        ...state,
        dochomedata: action.payload
      };
    case 'setCloseWizard':
      return {
        ...state,
        closeWizard: action.payload
      };
    case 'setloader':
      return {
        ...state,
        loaderstatus: action.payload
      };
    case 'setcategoryOpen':
      return {
        ...state,
        categoryOpen: action.payload
      };
    case 'setloader1':
      return {
        ...state,
        loaderstatus1: action.payload
      };
    case 'setOpenmodal':
      return {
        ...state,
        Openmodal: action.payload
      };
    case 'setFooterdata':
      return {
        ...state,
        footerdata: action.payload
      };
    case 'setsidebar':
      return {
        ...state,
        sidebar: action.payload
      };
    case 'setSelectedcatdata':
      return {
        ...state,
        selectedcatdata: action.payload
      };
    case 'sethtmldata':
      return {
        ...state,
        htmldata: action.payload
      };
    case 'setStaticpage':
      return {
        ...state,
        staticpage: action.payload
      };
    case 'setSubFilterData':
      return {
        ...state,
        subfilterdata: action.payload
      };
    case 'sethomecarouseldata':
      return {
        ...state,
        homecarouseldata: action.payload
      };
    case 'setsubscriptiondata':
      return {
        ...state,
        subscriptiondata: action.payload
      };
    case 'setfaqs':
      return {
        ...state,
        faqs: action.payload
      };
    case 'setcartdisplay':
      return {
        ...state,
        cartdisplay: action.payload
      };
    case 'setcatgoryslug':
      return {
        ...state,
        categoryslug: action.payload
      };
    case 'setsubcategoryslug':
      return {
        ...state,
        subcategoryslug: action.payload
      };
    case 'setHasmore':
      return {
        ...state,
        hasmore: action.payload
      };
    case 'setslectedkeys':
      return {
        ...state,
        slectedkeys: action.payload
      };
    case 'setvendor_token':
      return {
        ...state,
        vendor_token: action.payload
      };
    case 'setpage':
      return {
        ...state,
        page: action.payload
      };
    case 'setlangdata':
      return {
        ...state,
        langdata: action.payload
      };
    case 'setHomedata':
      return {
        ...state,
        homedata: action.payload
      };
    case 'setnavdata':
      return {
        ...state,
        navdata: action.payload
      };
    case 'setpopulardata':
      return {
        ...state,
        populardata: action.payload
      };
    case "setCartdata":
      return {
        ...state,
        cartdata: action.payload
      };
    case "satDeataidata":
      return {
        ...state,
        deataidata: action.payload
      }
    case "setrelateddata":
      return {
        ...state,
        relateddata: action.payload
      };
    case 'setfirstStepForm':
      return {
        ...state,
        firstStepForm: action.payload
      };
    case 'setformData':
      return {
        ...state,
        formData: action.payload
      };
    case 'setVerification_status':
      return {
        ...state,
        verificationStatus: action.payload
      };
    case 'setotp_msz':
      return {
        ...state,
        otp_msz: action.payload
      };
    case 'setotp_status':
      return {
        ...state,
        otp_status: action.payload
      };
    case 'setcheckStatus':
      return {
        ...state,
        checkStatus: action.payload
      };
    case 'setRazzer':
      return {
        ...state,
        razzer: action.payload
      };
   




    
    
    case 'setcurrentStep':
      return {
        ...state,
        currentStep: action.payload
      };
    case 'setVendorType':
      return {
        ...state,
        vendorType: action.payload
      };

    default:
      return state;
  }
};



// const initialState = {
//   loading: false,
//   authStatus: false,
//   userData: {},
//   HomePageData: [],
//   categoryData: [],
//   sidebar: false,
//   masterData: {},
//   error: null,
//   categoryOpen: 'level1',
//   presntcategoryOpen: "",
//   presntsubcategoryOpen: ""
// };

// export default (state = initialState, action) => {
//   switch (action.type) {
//     case 'homepage_detail':
//       return {
//         ...state,
//         loading: false,
//         HomePageData: action.payload
//       };
//     case 'masterData':
//       return {
//         ...state,
//         loading: false,
//         masterData: action.payload
//       };
//     case 'categorylistData':
//       return {
//         ...state,
//         loading: false,
//         categoryData: action.payload
//       };
//     case 'setsidebar':
//       return {
//         ...state,
//         sidebar: action.payload
//       };
//     case 'setcategoryOpen':
//       return {
//         ...state,
//         categoryOpen: action.payload
//       };
//     case 'setauthStatus':
//       return {
//         ...state,
//         authStatus: action.payload
//       };
//     case 'setpresentcategoryOpen':
//       return {
//         ...state,
//         presntcategoryOpen: action.payload
//       };
//     case 'setpresentsubcategoryOpen':
//       return {
//         ...state,
//         presntsubcategoryOpen: action.payload
//       };
//     default:
//       return state;
//   }
// };
