import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import Sidebar from "../docsidebar/Sidebar";
import { CiSearch } from "react-icons/ci";
import SearchBar from "./SearchBar";
import { BsSearch } from 'react-icons/bs';
import logo from "../../images/shopersbay_logo_docs.png"
const DocHeader = () => {
    const navdata = useSelector(state => state.masterdata.navdata);
    const miannavdata = navdata.filter(ele => ele.list_key === "docs_menu");
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });

    return (
        <div className="header-main">
            <div className="logo-header">
                <NavLink to="/">
                    <img className="logo-image" src={logo}></img>
                </NavLink>
            </div>
            {

                !isMobile &&
                <SearchBar />
                // <div className="Header-search-bar"><In<CiSearch className="Header-searchicon" /> Search the Documentation</div>

            }

            <div className="d-flex">
                {
                    !isMobile && <div className="header-cat">
                        {
                            miannavdata.map(ele => {
                                return (
                                    <div className="header-car-content" ><NavLink className="text-decoration-none" to={`/pages/${ele.url}`}>{ele.name}</NavLink></div>
                                )
                            })
                        }



                    </div>
                }
                {
                    !isMobile &&
                    <div className="header-buttion-signin-up">
                        <div className="header-button-signin">
                            Sign In
                        </div>
                        <div className="header-button-signup">
                            Sign Up
                        </div>
                    </div>

                }
            </div>


            {
                isMobile &&
                <div className="d-flex">
                    <NavLink to="/searchsuggestion" className="text-decoration-none"
                    // onClick={() => setsearchDetail([])} 
                    >
                        <div className='me-3'>
                            <BsSearch size="20" />
                        </div>
                    </NavLink>
                    <Sidebar />
                </div>
            }


        </div>
    )
}
export default DocHeader;