import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { ContactUs, Preloader } from "../components/common";

const Policies = () => {
  const { slug } = useParams();
  const footerdata = useSelector(state => state.masterdata.footerdata);
  const [data, setdata] = useState([]);
  useEffect(() => {
    if (footerdata.length > 0) {
      setdata(footerdata.filter(ele => ele.list_key == slug))
    }
  }, [slug, footerdata])
  return (
    <>
    {
        data.length > 0 ?
        <>
          <Container fluid className="mt-3">
            <div className="container">
              <div className="space-30"></div>
              <h1 className="policies-titles">{data[0].title}</h1>
              <div className="space-30"></div>
              <div dangerouslySetInnerHTML={{ __html: data[0].body }} />
            </div>
          </Container>
          <div className="space-20"></div>
          <ContactUs />
        </>
        : <div className='custom-height'></div>
      }
    </>
  )
}
export default Policies;