export function FirstStepForm(values) {
    let errors = {};
   
    if (values.mailid === '') {
        errors.mailid = 'Email address is required';
    }
    if (values.email === '') {
        errors.email = 'Email address is required';
    }
    if (values.registeremail === '') {
        errors.registeremail = 'Email address is required';
    }
    if (values.address1 === '' || values.address1 === null) {
        errors.address1 = 'H.NO address is required';
    }
    if (values.city === '' || values.city === null) {
        errors.city = 'City is required';
    }
    if (values.address2 === '' || values.address2 === null) {
        errors.address2= 'Area address is required';
    }
    
    if (values.name === '') {
        errors.name = 'Name is required';
    }
    if (values.otp === '') {
        errors.otp = 'OTP is required';
    }
    if (values.change_new_password === '') {
        errors.change_new_password = 'New Password is required';
    }
    if (values.change_old_password === '') {
        errors.change_old_password = 'Old Password is required';
    }
    if (values.billingname === '') {
        errors.billingname = 'Name is required';
    }

    if (values.phone === '') {
        errors.phone = 'Phone is required';
    }
    if (values.billingphone === '') {
        errors.billingphone = 'Phone is required';
    }
    if (values.mobile === '') {
        errors.mobile = 'Phone is required';
    }

    if (values.password === '') {
        errors.password = 'Password is required';
    }
    
    if (values.address === '') {
        errors.address = 'Address is required';
    }
    
    if (values.state === '' || values.state === null) {
        errors.state = 'State is required';
    }
    if (values.country === '' || values.country === null) {
        errors.country = 'Country is required';
    }
    return errors;
};

export function SecondStepForm(values) {
    let errors = {};
   
    if (values.registration_number === '') {
        errors.registration_number = 'Registration Number is required';
    }
    
    if (values.license_number === '') {
        errors.license_number = 'license number is required';
    }
   
    if (values.gst === '') {
        errors.gst = 'Gst is required';
    }

    if (values.address === '') {
        errors.address = 'Address is required';
    }
   
    return errors;
};
export function ThirdStepForm(values) {
    let errors = {};
   
    if (values.bank_name === '') {
        errors.bank_name = 'Registration Number is required';
    }
    
    if (values.holder_name === '') {
        errors.holder_name = 'Holder Name is required';
    }
   
    if (values.ifsc === '') {
        errors.ifsc = 'Ifsc is required';
    }

    if (values.account_number === '') {
        errors.account_number = 'Acc number is required';
    }
   
    return errors;
};

export function FourthStepForm(values){
    let errors = {};

    if (values.plans === '') {
        errors.plans = 'Please select any plan';
    }
    return errors;
}

export function ContactForm(values){
    let errors = {};

    if (values.name === '') {
        errors.name = 'Name is required';
    }
    if (values.email === '') {
        errors.email = 'Email address is required';
    }
    if (values.mailid === '') {
        errors.mailid = 'Email address is required';
    }
    if (values.phone === '') {
        errors.phone = 'Phone is required';
    }   
    if (values.subject === '') {
        errors.subject = 'Subject is required';
    }   
    return errors; 
}




export function newslater(values){
    let errors = {};

  
    if (values.email === ''|| values.email===null) {
        errors.email = 'Email address is required';
    }
  
    return errors; 
}
