import React from 'react';
import * as common from '../components/common'
// import Subscription from '../components/common/Subscription';
// import PricingBelow from '../components/common/PricingBelow';
// import CallToAction from '../components/common/CallToAction';
// import ContactUs from '../components/common/ContactUs';
// import Preloader from '../components/common/Preloader';
import { useSelector } from 'react-redux';

const SubscriptionPlans = () => {
    const homedata = useSelector(state => state.masterdata.homedata);
    const subscriptiondata = useSelector(state => state.masterdata.subscriptiondata);
    
    return (
        <>
        {
            Object.keys(homedata).length > 0 ?
            <div>
                {
                    homedata.postcategorydataList ?
                        homedata && homedata.postcategorydataList &&
                        homedata.postcategorydataList["Pricing Plans"] &&
                        <common.Subscription subscriptiondata={subscriptiondata} plansdata={homedata.postcategorydataList["Pricing Plans"]} /> : <div className='custom-height'></div>
                }
                {/* {
                    homedata && homedata.postcategorydataList &&
                    homedata.postcategorydataList["pricing"] &&
                    <common.PricingBelow pricebelow={homedata.postcategorydataList["pricing"]} />
                } */}
                {
                    homedata && homedata.postcategorydataList &&
                    homedata.postcategorydataList["Get In Touch"] &&
                    <common.CallToAction gettouch={homedata.postcategorydataList["Get In Touch"]} />
                }
                <div className="space-30"></div>
                <common.ContactUs />
            </div>
            :
            <div className='custom-height'></div>
        }
        </>
    )
}
export default SubscriptionPlans;