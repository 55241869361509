export const HOME = '/';
export const detail = '/detail/:slug';
export const policies = '/policies/:slug';
export const Category='/Category/:slugs';
export const SubCategory='/SubCategory/:slug';
export const blogdetails='/blogdetail/:slug';
export const documentation='/pages/docs/';
export const Searchsuggestion='/searchsuggestion/';
export const docdetails='/pages/docs/:slug';
export const faqs="pages/FAQs/";
export const Pricing="pages/Pricing";
export const Contactus="pages/Contact-Us";
export const Login="/login";
export const register="/signup";
export const plan="/plan";
