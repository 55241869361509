import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RiWhatsappFill } from "react-icons/ri";
import { FaMapMarkerAlt } from "react-icons/fa";
import logowhite from "../../images/shopersbay_logo_dark.png";
import Newsletterform from "./Newsletterform";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { SiMinutemailer } from "react-icons/si";
import { IoCloseSharp } from "react-icons/io5";
import Contactformaddress from './Contactformaddress';

const Footer = () => {

  const { navdata, footerdata, Openmodal } = useSelector(state => state.masterdata);
  const miannavdata = navdata.filter(ele => ele.list_lable === "Main Menu");
  const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
  const dispatch = useDispatch();

  const [filterFooter, setfilterfooter] = useState([])
  useEffect(() => {
    if (footerdata.length > 0) {

      const getData = footerdata.filter((ele) => ele.posts_type === 'policies');

      if (getData.length > 0) {
        setfilterfooter(getData)
      }

    }
  }, [footerdata])

  return (
    <>

      <div className="footerSecondContainer">
        <Container
          className="maxWidthContainerFluid paddingContainerFluid"
        >
          <Row className="footer-main-content" >
            <Col lg={3} md={3} className="mb-4">
              <Row>
                <Col lg={12} >
                  <img
                    alt=""
                    src={logowhite}
                    className="companyLog"
                  />
                  {/* <div className="footer-address">{localStorage.getItem("domain_descrption")}</div> */}
                  <p className="footer-address">{localStorage.getItem("domain_descrption")}</p>
                </Col>
              </Row>
            </Col>
            <Col lg={2} md={2} className="mb-4 footer-links">
              <Row>
                <Col lg={12}>
                  <h1 className="footerHeading">
                    LINKS
                  </h1>
                  <div className='header-below-list'>

                    <div className='dgfdh'>
                      {
                        miannavdata.map((ele, i) => {
                          return (
                            <div className="footer-category-list" key={i}>
                              <NavLink className={"text-decoration-none"} to={`/pages/${ele.url} `} target="_blank">
                                <span className='header-below-list-items footer-color-content'>  {ele.name}</span>
                              </NavLink>
                            </div>
                          )

                        })
                      }
                    </div>
                    {/* <span className='header-below-list-items header-below-list-more'> more <IoCaretDownSharp  /></span> */}
                  </div>
                </Col>
              </Row>
              <Row>

              </Row>
            </Col>
            <Col lg={3} md={3} className="mb-4">
              <Row>
                <Col lg={12}>
                  <h1 className="footerHeading">
                    CONTACT US
                  </h1>
                  <div className="midiacont">
                    <div className="footer-contact">
                      <div className="footer-contact-icon phone-info-icon">
                        <FaMapMarkerAlt className="footer-icons-main " />
                      </div>
                      <div className="footer-contact-desc phone-info-desc">
                        <h6>Visit Us Daily</h6>
                        <p>{localStorage.getItem("domain_address")}</p>
                      </div>
                    </div>
                    <div className="footer-contact">
                      <div className="footer-contact-icon phone-info-icon">
                        <NavLink to={`https://wa.me/${localStorage.getItem("vendor_contact")}`} className={"text-decoration-none"} target="_blank">
                          <RiWhatsappFill className="footer-icons-main " />
                        </NavLink>
                      </div>
                      <div className="footer-contact-desc phone-info-desc">
                        <h6>Have Any Questions?</h6>
                        <p><NavLink to={`https://wa.me/${localStorage.getItem("vendor_contact")}`} className={"text-decoration-none"} target="_blank">
                          +91- {localStorage.getItem("vendor_contact")}
                        </NavLink></p>
                      </div>
                    </div>
                    <div className="footer-contact">
                      <div className="footer-contact-icon phone-info-icon">
                        <NavLink to={`mailto:${localStorage.getItem("vendor_email")}`} className={"text-decoration-none"} target="_blank">
                          <SiMinutemailer className="footer-icons-main " />
                        </NavLink>
                      </div>
                      <div className="footer-contact-desc phone-info-desc">
                        <h6>Mail Us</h6>
                        <p><NavLink to={`mailto:${localStorage.getItem("vendor_email")}`} className={"text-decoration-none"} target="_blank">
                          {localStorage.getItem("vendor_email")}
                        </NavLink></p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

            </Col>
            <Col lg={4} className="footer-links">
              <Newsletterform />
            </Col>
          </Row>

          {

            !isMobile ?
              <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="footer-types">
                  <div className="middle_paragraph">
                    {
                      filterFooter.length > 0 &&
                      filterFooter.map((ele, index) => {
                        return (
                          <NavLink to={`/policies/${ele.list_key}`} className="dddddd footer-color-content" key={index}>
                            <div className="footer-line footer-color-content">
                              {index !== 0 && "  |  "}
                            </div>
                            {"  "}
                            {ele.title}{"  "}
                          </NavLink>
                        )
                      })
                    }

                  </div>
                </Col>   </Row>
              :
              <Row className="fotter-contact">

                {
                  filterFooter.length > 0 &&


                  filterFooter.map((ele, index) => {
                    return (

                      <Col lg={6} md={4} sm={6} xs={6} className="footer-text-contant footer-color-content" key={index}>
                        <NavLink to={`/policies/${ele.list_key}`} className={"text-decoration-none footer-color-content"} >
                          {ele.title}
                        </NavLink>
                      </Col>


                    )
                  })
                }

              </Row>
          }
          <Row >

            <Col lg={12}>
              <div className="footer_component">
                <p className="ourshop_com">{localStorage.getItem('website_copyright')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>



      <div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div className=''>
                <div className='contactform-wrong'> <IoCloseSharp  className='contactform-wrong-button'  
                data-bs-dismiss="modal" 
                /></div>
                <Contactformaddress />
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* <Modal size={'md'} show={Openmodal} centered className=" contact-form-div">
          <Modal.Body>
              <div className=''>


                  <div className='contactform-wrong'> <IoCloseSharp  className='contactform-wrong-button'  onClick={() => {
                      dispatch({
                          type: 'setOpenmodal',
                          payload: false
                      })
                  }
                  } /></div>
                  <Contactformaddress />
              </div>
          </Modal.Body>
      </Modal> */}

    </>
  );
};

export default Footer;
