import React, { useEffect } from "react";
import AppRouter from "./routers/AppRouter";
import Homedata from "./redux/actions/HomeAction"
import MasterAction from "./redux/actions/MasterAction";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga4";

const App = () => {

  const dispatch = useDispatch();

  const { getNewsDatalistdata,getNavigationlistdata,getSubscriptionlistdata ,getFaqListlistdata} = Homedata()
  const{ getMasterData ,getPostCategoryListdata, getSaticlistdata } = MasterAction()
  if (localStorage.getItem("ga_id") != null) {
    ReactGA.initialize(localStorage.getItem("ga_id"));
  }

  const homedata = useSelector(state => state.masterdata.homedata); 

 
  useEffect(()=>{
    dispatch(getNewsDatalistdata());
    dispatch(getNavigationlistdata());
    dispatch(getMasterData());
  },[])

  useEffect(() => {
    if(Object.keys(homedata).length > 0){
      dispatch(getSubscriptionlistdata());
      dispatch(getFaqListlistdata());
      dispatch(getPostCategoryListdata());
      dispatch(getSaticlistdata());
    }
  }, [homedata]);

  
  return (

    <AppRouter />

  );
};

export default App;
