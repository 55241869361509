import React, { useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DetailAction from "../redux/actions/DetailAction";
import * as common from '../components/common'
import { Helmet } from "react-helmet";
import fav from '../images/shopersbay_fav.png'
const Detailpage = () => {
  const { getdetaillistdata } = DetailAction()
  const homedata = useSelector(state => state.masterdata.homedata);
  const deataidata = useSelector(state => state.masterdata.deataidata);
  const { slug } = useParams();
  const dispatch = useDispatch();
  

  useEffect(() => {
    dispatch({
      type: 'satDeataidata',
      payload: []

    });
    const input_data = {
      slug: slug,
    };
    dispatch(getdetaillistdata(input_data));
  }, [slug])

  

  return (
    <div className=''>

      {
        deataidata.length > 0 ?
          <>

            <Helmet>
              <title>{localStorage.getItem("title") + " - " + deataidata[0].title}</title>
              <meta property='description' content={deataidata[0].short_description} />
              <meta property='keywords' content={deataidata[0].tags} />
              <meta property='title' content={deataidata[0].title} />
              <meta property="og:title" content={localStorage.getItem("title") + " - " + deataidata[0].title} />
              <meta property="og:description" content={ deataidata[0].short_description} />
              {/* <meta property="og:image" content="https://admin.shopersbay.com/assets/uploads/1005/ckeditor/28e4193482fa655385a5a9491be849bb.png" /> */}
              <meta property="og:type" content="website" />
            </Helmet>
            <common.DetailBanner data={deataidata[0]}/>
            <div className='detail-page'>
              <Container fluid>
                  <div className='space-90'></div>
                  <Row>
                    <Col xl={9} lg={8} className="mb-5 mb-lg-0 detail-body">
                      <div dangerouslySetInnerHTML={{ __html: deataidata[0].body }} />
                    </Col>
                    <Col xl={3} lg={4} className='service-widget-area'>
                        <common.RightSideComponent slug={slug} />
                    </Col>
                  </Row>
              </Container>
            </div>
            <div className='space-30'></div>

            {homedata && homedata.postcategorydataList && homedata.postcategorydataList["Get In Touch"] &&
              <common.CallToAction gettouch={homedata.postcategorydataList["Get In Touch"]} />
            }
            <div className="space-30"></div>
            <common.ContactUs />

          </>

        : <div className='custom-height'></div>



      }

      
    </div>
  )
}
export default Detailpage;
