import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

const DocHome = () => {
  return (
    <div className='document-homepage'>
      <Container>
        <Row>
          <Col lg={2} md={12} sm={12}></Col >
          <Col lg={10} md={12} sm={12}>
            <div >
              <h1>Shopersbay Documentation</h1>
              <h6>Shopersbay dynamically generates a beautiful, fully functional admin panel to manage your data. Learn how to create theme, custom storefront, or marketplace. Whether you’re just getting started, deep in the development process, or ready to distribute and monetize your work, Shopersbay’s docs, frameworks make building easy and efficient.</h6>
              <Row>
                <Col lg={4}  md={12} sm={12}>
                  <div className='category-section'>
                    <h2>Theme Builder</h2>
                    <p>Create a custom theme with React to help merchants express their unique brand identity, delivering a tailored storefront that enhances their visibility, personalization, and overall customer experience in headless commerce.</p>
                  </div>
                </Col>
                <Col lg={4}  md={12} sm={12}>
                  <div className='category-section'>
                    <h2>Inventory Management</h2>
                    <p>Manage product inventory efficiently with stock tracking, updates, low stock alerts, and real-time visibility from the admin panel for better control.</p>
                  </div>
                </Col>
                <Col lg={4}  md={12} sm={12}>
                  <div className='category-section'>
                    <h2>Point Of Sale</h2>
                    <p>A Point of Sale (POS) allows your businesses to manage in-store transactions, inventory, and sales reports, integrating with payment systems for efficient operations.</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default DocHome
