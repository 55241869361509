import React, { useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { AiOutlineSearch } from 'react-icons/ai';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import SearchAction from '../../redux/actions/SearchAction';
import { useSelector, useDispatch } from 'react-redux';
const SearchBar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const langdata = useSelector(state => state.masterdata.langdata);
    const slectedkeys = useSelector(state => state.masterdata.slectedkeys);
    const { slug } = useParams();
    const location = useLocation();
    const searchBarRef = useRef(null);

    const {
        searchDetail,
        searchValue,
        showsearchdata,
        categoryData,
        selectedItem,
    } = useSelector((state) => state.catlogdetail);

    const { getSearchProduct } = SearchAction();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                dispatch({ type: 'setshowdata', payload: false });
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dispatch]);

    const handleChange = (e) => {
        e.preventDefault();
        const input_data = { search: e.target.value };
        dispatch({ type: 'setValue', payload: e.target.value });
        dispatch(getSearchProduct(input_data));
    };

    // const handleKeyDown = (e) => {
    //     e.preventDefault();
    //     dispatch({ type: 'setValue', payload: e.target.value });

    //     const input_data = { search: e.target.value };
    // };

    useEffect(() => {
        if (location.pathname === `/search-result/${slug}`) {
            dispatch({ type: 'setValue', payload: slug });
        }
    }, [slug, dispatch, location.pathname]);

    // const searchButton = () => {
    //     if (searchValue !== '') {
    //         dispatch({ type: 'setshowdata', payload: false });
    //         navigate(`/search-result/${searchValue}`);
    //     }
    // };

    // const selected = (eventKey) => {
    //     dispatch({ type: 'setSelectedItem', payload: eventKey });
    // };

    // const expand = () => {
    //     dispatch({ type: 'setshowdata', payload: true });
    // };

    // const close = () => {
    //     dispatch({ type: 'setshowdata', payload: false });
    // };

    const handleMouseEnter = () => {
        dispatch({ type: 'setshowdata', payload: true });
    };

    const handleMouseLeave = () => {
        dispatch({ type: 'setshowdata', payload: false });
    };
    return (
        <div
            className='Header-search-bar'
            ref={searchBarRef}
            // onFocus={expand}
            // onBlur={close}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tabIndex="0"
        >
            <InputGroup>
                <InputGroup.Text id="search-button" >
                    <AiOutlineSearch />
                </InputGroup.Text>
                <Form.Control
                    placeholder={ "Search"

                    }
                    aria-label="Username"
                    size="md"
                    defaultValue={searchValue}
                    onChange={handleChange}
                    // onKeyUp={handleKeyDown}
                />

            </InputGroup>

            {showsearchdata && searchDetail.length > 0 && (

                <div
                    className="searchDetail"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {searchDetail.length > 0 ? (
                        searchDetail.map((ele, index) => {
                            const detail_url = ele.slug;
                            return (
                                <div
                                    key={index}
                                    className="text-decoration-none"
                                    onClick={() => {
                                        navigate(`/pages/docs/${detail_url}`);
                                        dispatch({ type: 'setshowdata', payload: false });
                                    }}
                                >
                                    <div className="searchbar-product-list">
                                        <h6 className="search-title-products">{ele.title}</h6>
                                        <div dangerouslySetInnerHTML={{ __html: ele.short_description }} />
                                    </div>
                                </div>
                            );
                        })
                    ) :
                        <div className="searchbar-product-list">No Record Found</div>
                    }
                </div>
            )}
        </div>
    );
};

export default SearchBar;
