import React, { useState ,useEffect} from 'react';
import { LuMenu } from "react-icons/lu";
import { IoClose} from "react-icons/io5";
import { useSelector,useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
const Sidebar = () => {
    const navdata = useSelector(state => state.masterdata.navdata);
    const miannavdata = navdata.filter(ele => ele.list_lable === "Main Menu");
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch()
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const sidebarStyle = {
        width: '100%',
        height: '100vh',
        // backgroundColor: '#212529',
        color: '#fff',
        backgroundColor: '#fff',
        color: 'black',
        position: 'fixed',
        overflowY: 'auto',
        top: isOpen ? '0' : '100%',
        zIndex: 10,
        // padding: '10px',
        left: "0",
        transition: 'left 0.9s ease-in-out',
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleShow = () => {
        // dispatch({
        //     type: 'setCloseWizard',
        //     payload: true
        // })
        window.open("https://login.shopersbay.com/signup?q=shopersbay", "_blank")
    };

    return (
        <div>
            <div style={sidebarStyle}>
                <div className='side-bar-close' onClick={toggleSidebar}>
                   <IoClose/>
                </div>
                <div className='sidebar-main-list'>
                {
                    miannavdata.map(ele => {
                        return (
                            <NavLink className={"text-decoration-none"} to={`/pages/${ele.url}`} onClick={toggleSidebar}>
                            <div className='sidebar-content'>
                            { ele.name }
                            </div>
                            </NavLink>
                        )
                    })
                }
                </div>
                <div className="header-buttion-signin-up">
                    <NavLink className="header-button-signin text-decoration-none"  to="https://login.shopersbay.com/shopersbay" target='_blank'>
                        Sign In
                    </NavLink>
                    <div className="header-button-signup" onClick={handleShow}>
                        Sign Up
                    </div>
                </div>

            </div>
            
            <div className="content">
                <div onClick={toggleSidebar} ><LuMenu  className='menubar-icon-sidebar'/></div>

            </div>
            
        </div>
    );
};







export default Sidebar;