import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import * as HiIcons from 'react-icons/hi';
import * as BsIcons from 'react-icons/bs';
import SubMenu from './SubMenu';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import { AllCategories } from '../common';

const Sidebar = () => {

    const sidebar = useSelector(state => state.masterdata.sidebar);
    const [categoryOpen, setcategoryOpen] = useState('level1');

    const isDesktoporLaptop = useMediaQuery({ query: `(max-width: 991px)` });

    const dispatch = useDispatch();

    useEffect(() => {
        if (sidebar == true) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }

    }, [sidebar])




    const showSidebar = () => {
        dispatch({ type: 'setsidebar', payload: !sidebar })
    };


    const SidebarNav = styled.nav`
  width: ${isDesktoporLaptop ? "100%" : "400px"};
  max-height: 100vh;
  display: flex;
  
  justify-content: center;
  position: fixed;
  box-shadow: ${isDesktoporLaptop ? "none" : "4px 0 10px 0 rgba(0,0,0,.4)"} ;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
`;

    const SidebarWrap = styled.div`
  width: 100%;
  margin-top:15%;
  background:white;
  color:black;
  height:100vh;
  overflow-y:scroll;
`;

    function SidebarsubCategory({ icon, type, title }) {

        return (
            <>
                {
                    type == "link" ?
                        <NavLink to="/my-account" className="text-decoration-none" onClick={showSidebar} >
                            <div className='category'>
                                <div className='category-leftside'>
                                    <div className='category-leftsideimg'>
                                        {icon}
                                    </div>
                                    <div className='category-leftsidetitle'>{title}</div>
                                </div>
                            </div>
                        </NavLink>
                        :
                        <div className='category' >
                            <div className='category-leftside'>
                                <div className='category-leftsideimg'>
                                    {icon}
                                </div>
                                <div className='category-leftsidetitle'>{title}</div>
                            </div>
                        </div>
                }
            </>
        )
    }

    return (
        <>
            <div style={{ display: "flex", cursor: "pointer" }} onClick={showSidebar} >
                <FaIcons.FaBars style={{ fontSize: isDesktoporLaptop ? "24px" : "" }} />
                {
                    !isDesktoporLaptop && <div className='ms-2' style={{ marginRight: "15px" }}>All</div>
                }

            </div>
            <SidebarNav sidebar={sidebar}>
                <div className='sidebar-top'>
                    <div className='sidebar-topleft'>
                        <h4 className='title'>
                            Documentation
                        </h4>
                    </div>

                    <div className='topleft-icon'>
                        <IoIcons.IoMdClose size="23" className='close-icon-sidebar' onClick={showSidebar} />
                    </div>


                </div>

                <SidebarWrap>
                    {
                        categoryOpen == "level1" ?
                            <>

                                <AllCategories />

                            </>


                            :
                            <AllCategories />

                        // SidebarData.map((item, index) => {
                        //     return (
                        //         (authStatus) ? <SubMenu item={item} key={index} /> : (!authStatus && item.title != 'Sign Out') && <SubMenu item={item} key={index} />
                        //     )
                        // })
                    }

                </SidebarWrap>
            </SidebarNav>
        </>
    );
};

export default Sidebar;
