import React, { useContext, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import fav from '../../images/shopersbay_fav.png'
const Loader = () => {

    const loaderstatus = useSelector(state => state.masterdata.loaderstatus);


    useEffect(() => {
        if (loaderstatus == true) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }

    }, [loaderstatus])
    return (
        <>
            {
                loaderstatus &&
                <div className="preloader">
                    <div>
                        <div className='preloader-div'>
                            {/* <ClipLoader className="cart-btn-loader" loading={true} size={50} /> */}
                            <img src={fav} alt="" className='w-50 img-fluid loader-image'></img>
                            {/* <Spinner animation="border" role="status" className='custom-spinner' /> */}
                        </div>
                    </div>

                </div>
            }
        </>


    );
};

export default Loader;