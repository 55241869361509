import React, { useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
  display: flex;
  color: black;
  align-items: center;
  list-style: none;
  padding-top:10px;
  text-decoration: none;
  font-size: 18px;
  font-weight:700;


  // &:hover {
  //   background: #252831;
  //   border-left: 4px solid #632ce4;
  //   cursor: pointer;
  //   color:white;
  // }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #414757;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
`;

const SubMenu = ({ customclass, title }) => {
  const [subnav, setSubnav] = useState(false);

  return (
    <>
      <SidebarLink className={customclass} >
        <div>
          <SidebarLabel>{title}</SidebarLabel>
        </div>
       
      </SidebarLink>
    </>
  );
};

export default SubMenu;
