import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
const MobileMultiple = ({ ...props }) => {
    const { keyfeature2, category } = props;
    return (
        <div>
            {
                keyfeature2.map((ele,index) => {
                    return (
                        <NavLink className=" text-decoration-none" to={`/blogdetail/${ele.slug}?q=${category}`}>
                        <div className='home-image-main-div keyfeature_home_img ' >
                            {/* <img className="homeimage mobile-images-multiple" src={picture}></img> */}
                            <Col lg={6} md={12} sm={12} className='homebanner-content'>
                            
                                <h1 className='honeimage-mobile'>{ele.title}</h1>
                                <div className='homeimage-content' dangerouslySetInnerHTML={{ __html: ele.description }}></div>
                            </Col>
                        </div >
                        </NavLink>
                    )
                })
            }

        </div>
    )
}
export default MobileMultiple;