import Apis from "../../services/Apis";
import { NavLink, useParams } from 'react-router-dom';
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
  const CategoryAction =()=>{
  
    const {getSubcategoryPostdatalist} =Apis();
    const category = useSelector(state => state.masterdata.cartdata);
    const cartdisplay = useSelector(state => state.masterdata.cartdisplay);
    // const getSubcategoryPostdatalistdata =(input_data)=>async dispatch => {
    //   // const page = useSelector(state => state.masterdata.page) 
    //   {
    //     if (input_data.page == 1){
    //       dispatch({
    //         type: 'setloader',
    //         payload: true
    //       });
    //     }else{
    //       dispatch({
    //         type: 'setloader',
    //         payload: false
    //       });
    //     }
    //   }
     
    //     const response = await getSubcategoryPostdatalist(input_data);
    //     // return response;
    //     if (response.data.message == 'Data fetched successfully') {
    //       dispatch({
    //         type: 'setHasmore',
    //         payload: true,
        
    //       });

    //       dispatch({
    //         type: 'setloader',
    //         payload: false
    //       });
    //       if (response.data.status == 'success') {
    //         dispatch({
    //           type: 'setCartdata',
    //           payload:response.data.data,
          
    //         });
    //         dispatch({
    //           type: 'setcartdisplay',
    //           payload: response.data?.data?.display_items?.posts || [],
    //         });
            
  
  
  
    //         if (input_data.page == 1){
    //           // alert(1)
    //           dispatch({
    //             type: 'setloader',
    //             payload: false
    //           });
    //           dispatch({
    //             type: 'setcartdisplay',
    //             payload: response.data?.data?.display_items?.posts || [],
            
    //           });
             
    //         }else{
    //           // alert(2)
    //           // dispatch({ type: 'setpage', payload: page + 1 })
    //           dispatch({
    //             type: 'setloader',
    //             payload: false
    //           });
    //           dispatch({
               
    //             type: 'setcartdisplay',
    //             // payload: [...cartdisplay, ...(response.data?.data?.display_items?.posts || [])],
    //             payload: [...cartdisplay.concat(response.data?.data?.display_items?.posts || [])],
                
    //           });
             
    //         }
            
    //           } else {
             
    //             dispatch({
    //               type: 'setCartdata',
    //               payload: []
    //             });
    //             dispatch({
    //               type: 'setcartdisplay',
    //               payload: []
    //             });
            
              
    //           }
    //     }
    //    else{
    //     dispatch({
    //       type: 'setHasmore',
    //       payload: false,
      
    //     });
    //     }; 
    //    }






       const getSubcategoryPostdatalistdata = (input_data) => async dispatch => {
 
        const response = await getSubcategoryPostdatalist(input_data);
    
        if (response.status == 200) {
          if (response.data.status == 'success') {
            dispatch({
              type: 'sethtmldata',
              payload: response.data.data
    
            });
          } else {
            dispatch({
              type: 'sethtmldata',
              payload: []
            });
          }
        }
      };
       
        

    
    return{
      getSubcategoryPostdatalistdata:getSubcategoryPostdatalistdata
    }

    
}
export default CategoryAction;