import React from 'react'
import Accordian from '../components/common/Accordian';
import Card from '../components/common/Card';
import Multipleimages from '../components/common/Multipleimages';
import OneimageSidecontent from '../components/common/OneimageSidecontent';
import Benefits from '../components/common/Benefits';
import Homeimage from '../components/common/Homeimage';
import Subscription from '../components/common/Subscription';
import CallToAction from '../components/common/CallToAction';
import ContactUs from '../components/common/ContactUs';
import Preloader from '../components/common/Preloader';

import Faqs from '../components/common/Faqs';
import { Helmet } from "react-helmet";
import { MobileMultiple } from '../components/common';
import { Row, Col, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
const HomePage = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const faqs = useSelector(state => state.masterdata.faqs);
    const subscriptiondata = useSelector(state => state.masterdata.subscriptiondata);
    const data = [
        {
            title: "What is Shopersbay and how does it work?---1",
            content: "Shopersbay is an unified commerce platform for business owners to initiate, operate, and expand their businesses seamlessly—whether it's in the digital realm, in brick-and-mortar stores, or anywhere in between."

        },
        {
            title: "What is Shopersbay and how does it work?---2",
            content: "Shopersbay is an unified commerce platform for business owners to initiate, operate, and expand their businesses seamlessly—whether it's in the digital realm, in brick-and-mortar stores, or anywhere in between."

        },
        {
            title: "What is Shopersbay and how does it work?---3",
            content: "Shopersbay is an unified commerce platform for business owners to initiate, operate, and expand their businesses seamlessly—whether it's in the digital realm, in brick-and-mortar stores, or anywhere in between."

        },
        {
            title: "What is Shopersbay and how does it work?---4",
            content: "Shopersbay is an unified commerce platform for business owners to initiate, operate, and expand their businesses seamlessly—whether it's in the digital realm, in brick-and-mortar stores, or anywhere in between."

        },
        {
            title: "What is Shopersbay and how does it work?---5",
            content: "Shopersbay is an unified commerce platform for business owners to initiate, operate, and expand their businesses seamlessly—whether it's in the digital realm, in brick-and-mortar stores, or anywhere in between."

        },
    ]
    const homedata = useSelector(state => state.masterdata.homedata);  

    return (
        <div>
            {
                Object.keys(homedata).length > 0 ?
                    <React.Fragment>
                        <Helmet>
                            <title>{localStorage.getItem("title") + " - " + homedata.postcategorydataList["Carousel Card"][0].title}</title>
                            <meta property='description' content={homedata.postcategorydataList["Carousel Card"][0].description} />
                            <meta property='keywords' content={homedata.postcategorydataList["Carousel Card"][0].tags} />
                            <meta property='title' content={homedata.postcategorydataList["Carousel Card"][0].title} />
                            <meta property="og:title" content={localStorage.getItem("title") + " - " + homedata.postcategorydataList["Carousel Card"][0].title} />
                            <meta property="og:description" content={homedata.postcategorydataList["Carousel Card"][0].description} />
                            <meta property="og:image" content="https://admin.shopersbay.com/assets/uploads/1005/ckeditor/28e4193482fa655385a5a9491be849bb.png" />
                        </Helmet>

                        {
                            homedata && homedata.postcategorydataList &&
                            homedata.postcategorydataList["Three Card Components"] &&
                            <Homeimage threecard={homedata.postcategorydataList["Three Card Components"]} imagedata={homedata.postcategorydataList["Carousel Card"]} category="Three Card Components" />
                        }


                        <Container fluid>

                            {
                                homedata && homedata.postcategorydataList &&
                                homedata.postcategorydataList["About"] &&


                                <OneimageSidecontent about={homedata.postcategorydataList["About"]} />
                            }
                            {
                                homedata && homedata.postcategorydataList &&
                                homedata.postcategorydataList["KEY FEATURES1"] &&
                                <div className="row">
                                    <div className="offset-lg-2 col-lg-8 text-center">
                                        <div className="space-20"></div>
                                        <div className="space-20"></div>
                                        <div className="space-20"></div>
                                        <div className="space-20"></div>
                                        <div className="ot-heading keyfeature-head">
                                            <span className="is_highlight">Key Features</span>
                                            <h2 className="main-head">{homedata.postcategorydataList["KEY FEATURES1"][0].title}</h2>
                                        </div>
                                        <div className="space-20"></div>
                                        <p className="px-xl-5 key-feature" dangerouslySetInnerHTML={{ __html: homedata.postcategorydataList["KEY FEATURES1"][0].description }} />
                                        <div className="space-30"></div>
                                        <div className="space-5"></div>
                                    </div>
                                </div>

                            }
                            {
                                homedata && homedata.postcategorydataList &&
                                homedata.postcategorydataList["Key Feature1 Features"] &&


                                <Card Card={homedata.postcategorydataList["Key Feature1 Features"]} category="Key Feature1 Features" />
                            }
                            {
                                !isMobile ?
                                    <>
                                        {
                                            homedata && homedata.postcategorydataList &&
                                            homedata.postcategorydataList["Keyfeature2"] &&
                                            <Multipleimages keyfeature2={homedata.postcategorydataList["Keyfeature2"]} category="Keyfeature2"/>
                                        }</>
                                    :
                                    <>
                                        {
                                            homedata && homedata.postcategorydataList &&
                                            homedata.postcategorydataList["Keyfeature2"] &&
                                            <MobileMultiple keyfeature2={homedata.postcategorydataList["Keyfeature2"]} category="Keyfeature2" />
                                        }</>
                            }
                            {
                                homedata && homedata.postcategorydataList &&
                                homedata.postcategorydataList["Our Benifits"] &&
                                <Benefits benifits={homedata.postcategorydataList["Our Benifits"]} category="Our Benifits" />
                            }
                            {
                                homedata && homedata.postcategorydataList &&
                                homedata.postcategorydataList["Get In Touch"] &&
                                <CallToAction gettouch={homedata.postcategorydataList["Get In Touch"]} />
                            }
                            {
                                homedata && homedata.postcategorydataList &&
                                homedata.postcategorydataList["Pricing Plans"] &&
                                <Subscription subscriptiondata={subscriptiondata} plansdata={homedata.postcategorydataList[
                                    "Pricing Plans"
                                ]} />
                            }


                            <Faqs />
                            <ContactUs />
                            <Row>



                            </Row>
                        </Container>


                    </React.Fragment> 
                    : 
                 
                    <div className='custom-height'></div>
                   
                    
            }
        </div>



    )
}
export default HomePage;