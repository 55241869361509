import React from 'react';
import Apis from "../../services/Apis";
import { useSelector } from 'react-redux';
import {notify} from '../../components/common'

const MasterAction = () => {
  
  const {addpostflag, HomepageComponent, getHomePagePostDataApi, getStaticPage, categoryListService, getMasterDataService, gvt, getPostCategoryList, placeOrder, saveCustomer, getVendorType } = Apis()

  const getHomepageData = () => async dispatch => {

    var input_data = {
      "page_key": "home"
    }
    const response = await HomepageComponent(input_data);
    if (response.status == 200) {
      if (response.data.status == 'success') {
        dispatch({
          type: 'homepage_detail',
          payload: [response.data]
        });
      } else {
        dispatch({
          type: 'homepage_detail',
          payload: []
        });
      }
    }
  };
  const getHomePagePostData = (array) => async dispatch => {

    var input_data = {
      'posts_type':array
    }

    const response = await getHomePagePostDataApi(input_data);
    // return response;
    if (response.data.status == 'success') {
      dispatch({
        type: 'setFooterdata',
        payload: response.data.data.postcategorydataList.Policies
      });
    } else dispatch({
      type: 'setFooterdata',
      payload: []
    });
  }

  const getMasterData = () => async dispatch => {
    const response = await getMasterDataService();
    if (response.status == 200) {
      
      if (response.data.status == 'success') {
        if (localStorage.getItem("cdn") === null || localStorage.getItem("cdn") != response.data.data.vendor.cdn) {
          localStorage.setItem('cdn', response.data.data.vendor.cdn_domain);
        }
        if (localStorage.getItem("footer_logo") === null || localStorage.getItem("footer_logo") != response.data.data.vendor.footer_logo) {
          localStorage.setItem('footer_logo', response.data.data.vendor.footer_logo);
        }
        if (localStorage.getItem("vendor_contact") === null || localStorage.getItem("vendor_contact") != response.data.data.vendor.vendor_contact) {
          localStorage.setItem('vendor_contact', response.data.data.vendor.vendor_contact);
        }
        if (localStorage.getItem("vendor_email") === null || localStorage.getItem("vendor_email") != response.data.data.vendor.vendor_email) {
          localStorage.setItem('vendor_email', response.data.data.vendor.vendor_email);
        }
        if (localStorage.getItem("domain_address") === null || localStorage.getItem("domain_address") != response.data.data.vendor.domain_address) {
          localStorage.setItem('domain_address', response.data.data.vendor.domain_address);
        }
        if (localStorage.getItem("website_copyright") === null || localStorage.getItem("website_copyright") != response.data.data.vendor.website_copyright) {
          localStorage.setItem('website_copyright', response.data.data.vendor.website_copyright);
        }
        if (localStorage.getItem("address") === null || localStorage.getItem("address") != response.data.data.vendor.address) {
          localStorage.setItem('address', response.data.data.vendor.address);
        }
        if (localStorage.getItem("favicon") === null || localStorage.getItem("favicon") != response.data.data.vendor.favicon) {
          localStorage.setItem('favicon', response.data.data.vendor.favicon);
        }
        if (localStorage.getItem("ga_id") === null || localStorage.getItem("ga_id") != response.data.data.vendor.ga_id) {
          localStorage.setItem('ga_id', response.data.data.vendor.ga_id);
        }
        if (localStorage.getItem("title") === null || localStorage.getItem("title") != response.data.data.vendor.title) {
          localStorage.setItem('title', response.data.data.vendor.agency_name);
        }
        if (localStorage.getItem("domain_descrption") === null || localStorage.getItem("domain_descrption") != response.data.data.vendor.domain_descrption) {
          localStorage.setItem('domain_descrption', response.data.data.vendor.domain_descrption);
        }
        if (localStorage.getItem("company_logo") === null || localStorage.getItem("company_logo") != response.data.data.vendor.company_logo) {
          localStorage.setItem('company_logo', response.data.data.vendor.company_logo);
        }
        if (localStorage.getItem("currency") === null || localStorage.getItem("currency") != response.data.data.vendor.currency) {
          localStorage.setItem('currency', response.data.data.vendor.currency);
        }
        dispatch({
          type: 'masterData',
          payload: response.data.data
        });
      } else {
        dispatch({
          type: 'masterData',
          payload: []
        });
      }
    }
  };

  const getPostCategoryListdata = () => async dispatch => {

    const response = await getPostCategoryList();


    if (response.status == '200') {
      // if (response.data.status == 'success') {
      dispatch({
        type: 'setStaticpage',
        payload: response.data.data

      });
    } else {
      dispatch({
        type: 'setStaticpage',
        payload: []
      });
    }
  };

  const getVendorToken = () => async dispatch => {
    // dispatch({
    //   type: 'setloader',
    //   payload: true
    // });

    const response = await gvt();

    if (response.status == 200) {
      if (response.data.status == 'success') {
        dispatch({
          type: 'setvendor_token',
          payload: response.data.vt
        });
        // dispatch({
        //   type: 'setloader',
        //   payload: false
        // });
        localStorage.setItem('qwerty', response.data.vt);
      }
    }
  };

  const getcategorylistData = () => async dispatch => {
    const response = await categoryListService();

    if (response.status == 200) {
      if (response.data.status == 'success') {
        dispatch({
          type: 'categorylistData',
          payload: response.data.categoryList
        });
      } else {
        dispatch({
          type: 'categorylistData',
          payload: []
        });
      }
    }
  };

  const getVendorTypeList = () => async dispatch => {
    const response = await getVendorType();
    if (response.status == 200) {
      if (response.data.status == 'success') {
        dispatch({
          type: 'setVendorType',
          payload: response.data.data
        });
      }
    }
  };

  const getSaticlistdata = () => async dispatch => {
    const response = await getStaticPage()
    if (response.data.status == 'success') {
      dispatch({
        type: 'setFooterdata',
        payload: response.data.pagelist,

      });
    } else {
      dispatch({
        type: 'setFooterdata',
        payload: []
      });
    }
  }

  const addFlagApi = (input_data) => async dispatch => {
    const response = await addpostflag(input_data);
    if (response.data.status == "success") {
      notify('add2cart', response.data.message, false)
      
    }else{
      notify('add2cart', response.data.message, false)

    }

  };

  

  return {
    getHomepageData: getHomepageData,
    getMasterData: getMasterData,
    getPostCategoryListdata: getPostCategoryListdata,
    getcategorylistData: getcategorylistData,
    getVendorToken: getVendorToken,
    // postplaceOrder: postplaceOrder,
    getVendorTypeList: getVendorTypeList,
    getHomePagePostData: getHomePagePostData,
    getSaticlistdata: getSaticlistdata,
    addFlagApi:addFlagApi,
  }
};

export default MasterAction;