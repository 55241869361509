import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import aboutimg from "../../images/about_shopersbay.webp"
const OneimageSidecontent = ({ about }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
  
    return (
        <Container>
            <Row className='mt-5'>
                {
                    isMobile &&
                    <div className="ot-heading">
                        <span className="is_highlight">about shopersbay</span>
                        <h3 className="main-head">{about[0].title}</h3>
                    </div>
                }


                <Col lg={7} md={12} sm={12}>
                    <img className='oneimage' src={aboutimg} alt=""></img>
                    {/* <img className='oneimage' src={about[0].image}></img> */}
                </Col>
                <Col className='onesideimage-content' lg={5} md={12} sm={12}>

                    {

                        !isMobile &&
                        <div className="ot-heading">
                            <span className="is_highlight">about shopersbay</span>
                            <h3 className="main-head">{about[0].title}</h3>
                        </div>
                    }

                    <div className="space-20"></div>
                    <div className="space-2"></div>
                    <div dangerouslySetInnerHTML={{ __html: about[0].description }} />
                    <div className="space-20"></div>
                    <div className="about-button">
                        <NavLink className="project-button text-decoration-none1" to={`/blogdetail/${about[0].slug}`}>
                            EXPLORE MORE
                        </NavLink>
                    </div>
                    <div className="space-20"></div>


                </Col>

            </Row>
        </Container>
    )
}
export default OneimageSidecontent;