import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import { IoCloseSharp } from "react-icons/io5";
import Contactformaddress from './Contactformaddress';
import bg from '../../images/background2.webp'
function CallToAction({ gettouch }) {
    const dispatch = useDispatch();

    const backgroundImage = localStorage.getItem("cdn") + gettouch[0].image;

    const Openmodal = useSelector(state => state.masterdata.Openmodal);
    return (
        <section className="cta" style={{ backgroundImage: `url('${bg}')` }}>
            <div className="container">
                <div className='space-80'></div>
                <div className="row align-items-center">
                    <div className="col-lg-8 mb-4 mb-lg-0">
                        <h2 className="mb-0 text-white" dangerouslySetInnerHTML={{ __html: gettouch[0].description }} />
                    </div>
                    <div className="col-lg-4 text-lg-right ">
                        <div className="ot-button">
                            {/* Using onClick event handler in React */}
                            <button class="project-button"  
                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                            >
                                GET QUOTE
                            </button>

                        </div>
                        {/* Removed the commented out <a> tag */}
                    </div>
                </div>
            </div>
            <div className='space-80'></div>

            

        </section >
    );
}

export default CallToAction;
