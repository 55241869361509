import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { InputBox } from '../common'
import ProductAction from '../../redux/actions/ProductAction';
import { ContactForm } from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
const Contactformaddress = () => {
  const dispatch = useDispatch()
  const { saveContactFormdatalist } = ProductAction();
  const loaderstatus1 = useSelector(state => state.masterdata.loaderstatus1);
  
  const [formValues, setFormValues] = React.useState(
    {
      "name": "",
      "phone": "",
      "email": "",
      "subject": "",
      "message":"",
     
    }

  );
  const { values, errors, setErrors, handleChange, handleSubmit } = useFormvalidation(
    handleNext,
    ContactForm,
    formValues
  );

  function handleNext() {
    dispatch(saveContactFormdatalist(values,setFormValues))
  }

  return (
    <div>
     
        
          <Row className='contactformaddress-main-div'>
            <div class="ot-heading">
              <div className='heading-contact'>  <span class="line-side"></span><span class="line-content"> TO GET STARTED</span></div>

              <h3 class="main-head">Ready to<span class="hl-text"> Get Started?</span></h3>
            </div>
            <p>Please, fulfill the form to get a consultation.</p>
            <Col lg={6}>
              <InputBox label={"Name"} id={"name"} handleChange={handleChange} errors={errors} />
            </Col>
            <Col lg={6}>
              <InputBox label={"Phone"} id={"phone"} handleChange={handleChange} errors={errors} value={values.phone}/>
            </Col>
            <Col lg={6}>
              <InputBox label={"Email"} id={"email"} handleChange={handleChange} errors={errors} value={values.email} />
            </Col>
            <Col lg={6}>
              <InputBox label={"Subject"} id={"subject"} handleChange={handleChange} errors={errors} value={values.subject} />
            </Col>
            {/* <Col lg={12}>
              <InputBox label={"Message"} id={"Message"} handleChange={handleChange} errors={errors} />
            </Col> */}
            <label className='contactformaddress-message'>Message</label>
            <textarea  className='contactaddress-textarea'
              id="message" name="message"
           
              onChange={handleChange}
              value={values.message}/>

            
            
            <div style={{ position: "relative",pointerEvents:loaderstatus1 && 'none' }} className={`product-detail-buttongroup login-register-btn `} onClick={handleSubmit}>
              <div className={`product-detail-button header-button-signup`} >
                Submit
              </div>
              {
                loaderstatus1 &&
                <div style={{ position: 'absolute', top: '50%', left: '60%', transform: 'translate(-50%, -50%)' }}>
                  <Spinner animation="border" role="status" className='custom-spinners1' />
                </div>
              }
            </div>

            {/* <div className='about-main'>
      <div className="about-button">READ MORE</div>
      </div> */}

          </Row>
          
      


      
    </div>
  )
}
export default Contactformaddress;