import React from 'react'
import { ContactUs, ContactComponent } from '../components/common';
const Contact = () => {
    return (
        <>
            <ContactComponent/>
            <ContactUs />
        </>
    )
}
export default Contact;