import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import logo from "../../images/shopersbay_logo.png"
import Sidebar from "./Sidebar";
const Header = () => {
    const dispatch = useDispatch()
    const navdata = useSelector((state) => state.masterdata.navdata);
    const closeWizard = useSelector((state) => state.masterdata.closeWizard);
    const miannavdata = navdata.filter((ele) => ele.list_lable === "Main Menu");
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });

    const handleShow = () => {
        dispatch({
            type: 'setCloseWizard',
            payload: true
        })

    };
    return (
        <>
            <div className="header-main">
                <div className="logo-header">
                    <NavLink to="/">
                        <img className="logo-image" src={logo} alt=""></img>
                    </NavLink>
                </div>
                <div className="d-flex">
                    {
                        !isMobile && <div className="header-cat">
                            {
                                miannavdata.map((ele, i) => {
                                    return (
                                        <NavLink className={"text-decoration-none"} to={`/pages/${ele.url} `} target="_blank" key={i}>
                                            <div className="header-car-content">{ele.name}</div>
                                        </NavLink>

                                    )
                                })
                            }



                        </div>
                    }
                    {
                        !isMobile &&
                        <div className="header-buttion-signin-up">
                            <div className="header-button-signin">
                                <NavLink className={"text-decoration-none "} to="https://login.shopersbay.com/shopersbay" target="_blank">
                                    Sign In
                                </NavLink>
                                
                            </div>
                            <div className="header-button-signup">
                                <NavLink className={"text-decoration-none"} to="https://login.shopersbay.com/signup?q=shopersbay" target="_blank">
                                Sign Up
                                </NavLink>
                                
                            </div>
                        </div>

                    }
                </div>


                {
                    isMobile && <Sidebar />
                }


            </div>
        </>
    );
};
export default Header;
