import React, { useState } from 'react'
import { Col } from 'react-bootstrap';
import { GiArtificialIntelligence } from "react-icons/gi";
import { BsGraphUpArrow } from "react-icons/bs";
import { MdRocketLaunch } from "react-icons/md";
import { NavLink, useSearchParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import bg from '../../images/slider-bg.webp'
const Homeimage = ({ ...props }) => {
    const { threecard, imagedata, category } = props;
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const dispatch = useDispatch()
    const handleShow = () => {
        dispatch({
            type: 'setCloseWizard',
            payload: true
        })

    };
    return (
        <>
        <div>
            <div className='home-image-main-div'>

                <div className='home-image-main-div' >
                    {/* <img className="homeimage" src={localStorage.getItem("cdn") + imagedata[0].image} alt=""></img> */}
                    <img className="homeimage" src={bg} alt=""></img>
                    <Col lg={6} md={12} sm={12} className='homebanner-content'>
                        <h1 className='honeimage-mobile' dangerouslySetInnerHTML={{ __html: imagedata[0].description }}></h1>
                        {
                            !isMobile &&
                            <div className="space-20"></div>

                        }
                       
                        <div className='get-button'>
                        <div className="project-button" 
                        >
                            <NavLink className={"text-decoration-none"} to="https://login.shopersbay.com/signup?q=shopersbay" target="_blank">
                            GET STARTED
                            </NavLink>
                        </div>
                        <button type="button" class="project-button" data-bs-target="#exampleModal" data-bs-toggle="modal">
                        GET QUOTE
                        </button>
                        
                        </div>
                    </Col>
                </div >
                <div className='feature'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 mb-2 mb-md-0">
                                <NavLink className={"text-decoration-none"} to={`/blogdetail/designed-to-excel?q=${category}`}>
                                    <div className="icon-box icon-box-2 feature-box ">
                                        <div className="icon-main threecard">
                                            <BsGraphUpArrow />
                                        </div>
                                        <div className="content-box">
                                            <h3 className="title-box">Designed to Excel</h3>
                                            <p>Custom Solution for your unique needs</p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-2 mb-md-0">
                                <NavLink className={"text-decoration-none"} to={`/blogdetail/tailored-intelligence?q=${category}`}>
                                    <div className="icon-box icon-box-2 feature-box ">
                                        <div className="icon-main threecard">
                                            <GiArtificialIntelligence />
                                        </div>
                                        <div className="content-box">
                                            <h3 className="title-box">Tailored Intelligence</h3>
                                            <p>Blend of Growth Focused and Insightful Analytics</p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-2 mb-md-0">
                                <NavLink className={"text-decoration-none"} to={`/blogdetail/cutting-edge-technology?q=${category}`}>
                                    <div className="icon-box icon-box-2 feature-box">
                                        <div className="icon-main threecard">
                                            <MdRocketLaunch />
                                        </div>
                                        <div className="content-box">
                                            <h3 className="title-box">Cutting-Edge Technology</h3>
                                            <p>Crafted for Today, Scaling into Tomorrow</p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>

                        </div>
                    </div>
                </div>

            </div>



        </div>
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              ...
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Understood</button>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}
export default Homeimage;