import { DesktopLayout, DocLayout } from '../components/layout';
// import { UseData } from '../hooks';
// import { StateContext } from "../components/context/ManageStates";
import * as ROUTES from '../constants/routes';
import React, { Suspense, lazy, useEffect, useContext } from 'react';
import { Route, BrowserRouter as Router, Routes, } from 'react-router-dom';
import Faqs from '../components/common/Faqs';
import Subscription from '../components/common/Subscription';
import { Detailpage, CatSidebar, Detail, SubscriptionPlans, Contact } from '../views';
import * as view from '../views';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import Homedata from "../redux/actions/HomeAction"
import { useDispatch } from "react-redux";
import MasterReducer from '../redux/reducers/MasterReducer';
import { ScrollTop } from '../hooks';
import { DocHome } from '../components/common';
const HomePage = lazy(() => import("../../src/views/HomePage"));

const AppRouter = () => {
  // const homedata = useSelector(state => state.MasterReducer.homedata);
  
  const detaildata = useSelector(state => state.masterdata.deataidata);
  const relateddata = useSelector(state => state.masterdata.relateddata);
  //   const mapStateToProps = state => ({
  //     homedata: state.homeReducer.homedata,
  // });

  // const dispatch = useDispatch();
  // const { getNewsDatalistdata } = Homedata()
  // useEffect(()=>{
  //   // dispatch(getNewsDatalistdata())
  // },[catagorydata])

  // const { homedata } = useContext(StateContext);
  // const { getNewsDatalistdata } = UseData();

  // useEffect(() => {
  //     getNewsDatalistdata();
  // }, []);

  return (
    <Router >

      <>
        <Suspense fallback={<div className='loading-main'>
        </div>}>
          <Routes>

            <Route
              element=
              {
                <ScrollTop>

                  <DesktopLayout>
                    <HomePage 
                    />
                  </DesktopLayout>
                </ScrollTop>
              }
              exact
              path={ROUTES.HOME}
            />
            <Route
              element=
              {
                <ScrollTop>

                  <DesktopLayout>
                    <Detailpage
                    />
                  </DesktopLayout>
                </ScrollTop>
              }
              exact
              path={ROUTES.blogdetails}
            />
            <Route
              element=
              {
                <ScrollTop>

                  <DesktopLayout>
                    {/* <Faqs/> */}
                  </DesktopLayout>
                </ScrollTop>
              }
              exact
              path={ROUTES.faqs}
            />
            <Route
              element=
              {
                <ScrollTop>

                  <DesktopLayout>
                    <Contact />
                  </DesktopLayout>
                </ScrollTop>
              }
              exact
              path={ROUTES.Contactus}
            />
            <Route
              element=
              {
                <ScrollTop>

                  <DesktopLayout>
                    <SubscriptionPlans />
                  </DesktopLayout>
                </ScrollTop>
              }
              exact
              path={ROUTES.Pricing}
            />
            <Route
              element=
              {
                <ScrollTop>

                  <DocLayout>
                    <Detail
                    />
                  </DocLayout>
                </ScrollTop>
              }
              exact
              path={ROUTES.docdetails}
            />
            <Route
              element=
              {
                <ScrollTop>

                  <DocLayout>
                    <DocHome />
                  </DocLayout >
                </ScrollTop>
              }
              exact
              path={ROUTES.documentation}
            />
            <Route
              element={
                <DesktopLayout>
                  <ScrollTop>
                    <view.Policies />
                  </ScrollTop>
                </DesktopLayout>
              }
              exact
              path={ROUTES.policies}
            />
            <Route
              element={
                <ScrollTop>
                  <view.Search />
                </ScrollTop>
              }
              exact
              path={ROUTES.Searchsuggestion}
            />

            {/* <Route
            element={
              <view.Extract />
            }
            path={"/Extract"}

          /> */}
            <Route
              element={
                <ScrollTop>
                  <DesktopLayout>
                    {/* <Suspense> */}
                    <Detail
                      detaildata={detaildata}
                      relateddata={relateddata}
                    />
                    {/* </Suspense> */}
                  </DesktopLayout>
                </ScrollTop>
              }
              path={ROUTES.detail}
            // path={"/detail"}

            />
         

          </Routes>
          

        </Suspense>


      </>
    </Router>

  )

};

export default AppRouter;
