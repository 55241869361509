import React from 'react'
import { useSelector } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import Accordian from './Accordian';
const Faqs = () => {
    const faqs = useSelector(state => state.masterdata.faqs);
    return (
        <div className='container'>
            <div class="ot-heading text-center">
              
                <div class="space-30"></div>
                <div class="space-30"></div>
                <span class="is_highlight">FAQ'S</span>
                <h3 class="main-head">Frequently asked questions</h3>
            </div>
            <Row>
                <Col className='frequently-asked-col' lg={6} md={12} sm={12}>
                    {
                        faqs && faqs.length > 0 &&
                        <Accordian faqsdata={faqs.slice(0, 5)} />
                    }

                </Col>
                <Col className='frequently-asked-col' lg={6} md={12} sm={12}>
                    {
                        faqs && faqs.length > 0 &&
                        <Accordian faqsdata={faqs.slice(5, 10)} />
                    }

                </Col>
                <div class="space-30"></div>
            </Row>
        </div>
    )
}
export default Faqs;