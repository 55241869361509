import { useSelector } from "react-redux";
import Apis from "../../services/Apis";
import { notify } from "../../components/common";
const ProductAction = () => {
  const {  getProductDetailService, SaveRecentlyViewedApi,getRelatedItemsApi,saveContactFormdata ,saveNewsLetterdata,} = Apis();


  const getProductDetail = (input_data) => async dispatch => {
    const response = await getProductDetailService(input_data);
    if (response.data.status == "success") {
      dispatch({
        type: 'setproductDetailData',
        payload:  response.data.productList
      });
    } else {
      dispatch({
        type: 'setproductDetailData',
        payload: []
      });
    }
  };
  const saveNewsLetterdatalist = (input_data) => async dispatch => {
    const response = await saveNewsLetterdata(input_data);
    if (response.data.status == "success") {
      dispatch({
        type:'setprodDetailBottomSection',
        payload: response.data.data
      });
      notify('add2cart', response.data.mesaage, false)
    } else {
      dispatch({
        type: 'setprodDetailBottomSection',
        payload: []
      });
      notify('add2cart', response.data.mesaage, false)
    }
  };

  const saveContactFormdatalist = (input_data, setFormValues) => async dispatch => {
    dispatch({
      type: 'setloader1',
      payload: true
    });
    const response = await saveContactFormdata(input_data);
    if (response.data.status == "success") {
      notify('add2cart', response.data.mesaage, false)
    //   dispatch({
    //     type: 'setOpenmodal',
    //     payload: false
    // })

    setFormValues(
      {
        "name": "",
        "phone": "",
        "email": "",
        "subject": "",
        "message":"",
       
      }
    )
      // dispatch({
      //   type:'setprodDetailBottomSection',
      //   payload: response.data.data
      // });
      dispatch({
        type: 'setloader1',
        payload: false
      });
    } else {
      notify('add2cart', response.data.mesaage, false)
      // dispatch({
      //   type: 'setprodDetailBottomSection',
      //   payload: []
      // });
      dispatch({
        type: 'setloader1',
        payload: false
      });
    }
  };
  const getRelatedItems = (input_data) => async dispatch => {
    const response = await getRelatedItemsApi(input_data);
    if (response.data.status == "success") {
      dispatch({
        type:'setprodDetailBottomSection',
        payload: response.data.data
      });
    } else {
      dispatch({
        type: 'setprodDetailBottomSection',
        payload: []
      });
    }
  };
  const SaveRecentlyViewed = (input_data) => async dispatch => {
    await SaveRecentlyViewedApi(input_data);
  };

  

  return {
    getProductDetail:getProductDetail,
     getRelatedItems:getRelatedItems,
     SaveRecentlyViewed:SaveRecentlyViewed,
     saveContactFormdatalist:saveContactFormdatalist,
     saveNewsLetterdatalist:saveNewsLetterdatalist,
    
  }
};

export default ProductAction;
