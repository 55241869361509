import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import CategoryAction from '../redux/actions/CategoryAction';
import Detail from './Detail';

const CatSidebar = () => {
    const [selectedDescription, setSelectedDescription] = useState("");
    const [openCategories, setOpenCategories] = useState({});
    const [selectButton, setSelectButton] = useState(true);
    const { htmldata, deataidata } = useSelector(state => state.masterdata);
    const categorydata = useSelector(state => state.masterdata.staticpage);
    const dispatch = useDispatch();
    const { getSubcategoryPostdatalistdata } = CategoryAction();
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
    // useEffect(() => {
    //     dispatch(getSubcategoryPostdatalistdata({ subcategory_slug: selectedDescription, page: 1 }));
    // }, [selectedDescription]);

    const handleSubcategoryClick = slug => {
        setSelectedDescription(slug);
    };

    const toggleCategory = category => {
        setOpenCategories(prev => ({
            ...prev,
            [category]: !prev[category]
        }));
    };
    //  useEffect(()=>{
    //     const dochomedata = () => {


    //     };
    //  },[])


    // const filterPostsForSubcategory = (subcategorySlug) => {
    //     // Safely access posts only if the structure is as expected
    //     return htmldata?.display_items?.posts?.filter(post => post.subcategory_slug === subcategorySlug) || [];
    // };




    const Category = ({ category, subcats, posts, onToggle, isOpen, catslug }) => {
        return (
            <div className='catsidebar'>
                <div style={{ cursor: 'pointer' }} className='catsidebar-cattitle'>
                    {category}
                    {/* {selectedDescription === catslug && filterPostsForSubcategory(catslug).map(post => (
                        <NavLink className={"text-decoration-none"} to={`/pages/docs/${post.slug}`}>
                            <div className='subcategorydata' onClick={() => dispatch({
                                type: 'setdochomedata',
                                payload: false
                            })} key={post.id}>{post.title}</div>
                        </NavLink>
                    ))} */}
                </div>
                {subcats.map(subcat => (
                    <div key={subcat.name}>
                        <div className='document-subcategory'>
                            <div className='catsidebar-subtitle'>
                                <NavLink className={"text-decoration-none"} to={`/pages/docs/${subcat.slug}`} onClick={() => dispatch({
                                    type: 'setdochomedata',
                                    payload: false
                                })}>
                                    {subcat.subcategory_name}
                                </NavLink>
                            </div>
                            {subcat.posts.map(post => (
                                <NavLink className={"text-decoration-none"} to={`/pages/docs/${post.slug}`}>
                                    <div className='subcategorydata' onClick={() => dispatch({
                                        type: 'setdochomedata',
                                        payload: false
                                    })} key={post.id}>{post.title}</div>
                                </NavLink>
                            ))}
                        </div>
                    </div>
                ))}

                <div className='document-subcategory'>
                    {posts.map(post => (
                        <NavLink className={"text-decoration-none"} to={`/pages/docs/${post.slug}`}>
                            <div className='subcategorydata' onClick={() => dispatch({
                                type: 'setdochomedata',
                                payload: false
                            })} key={post.id}>{post.title}</div>
                        </NavLink>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <>
            {!isMobile && selectButton &&
                <Col lg={2} className='cartside-bar-main'>
                    {categorydata.map(ele => (
                        <Category
                            key={ele.category_name}
                            category={ele.category_name}
                            subcats={ele.child}
                            catslug={ele.slug}
                            posts={ele.posts}
                            onSelect={handleSubcategoryClick}
                            onToggle={toggleCategory}
                            isOpen={true}
                        />
                    ))}
                </Col>

            }


        </>
    );
}

export default CatSidebar;
