import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsChevronLeft, BsSearch } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import SearchAction from '../redux/actions/SearchAction';
import { useSelector, useDispatch } from 'react-redux';
// import logo from '../images/bg_image.webp';
const Search = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const searchDetail = useSelector(state => state.catlogdetail.searchDetail);
    const showsearchdata = useSelector(state => state.catlogdetail.showsearchdata);

    const [searchValue, setValue] = useState('');
    const { getSearchProduct } = SearchAction();

    const handleChange = (e) => {
        e.preventDefault(); // prevent the default action
        setValue(e.target.value)
        var input_data = {
            "search": e.target.value
        }
        dispatch(getSearchProduct(input_data))
        // setName(e.target.value); // set name to e.target.value (event)
    };


    const searchButton = () => {
        if (searchValue != '') {
            navigate(`/search-result/${searchValue}`)
        }
    }

    return (
        <div>
            <div className='search'>
                <BsChevronLeft onClick={goBack} size="24" className='search-left-icon' />
                <input type='text' className="custom-search-input" placeholder='Search for documentation' onChange={handleChange} />
            </div>
            {
                (showsearchdata) && searchDetail.length > 0 ?
                    <div className='searchDetail'>
                        {
                            searchDetail.length > 0 ?

                                searchDetail.map(ele => {
                                    return (
                                        <NavLink onClick={() => {
                                            dispatch({ type: 'setshowdata', payload: false })
                                        }} to={`/pages/docs/${ele.slug}`} className="text-decoration-none">
                                            <div className='searchbar-product-list'>
                                                <h3>{ele.title}</h3>
                                                <div dangerouslySetInnerHTML={{ __html: ele.short_description }} />
                                            </div>
                                        </NavLink>
                                    )
                                })
                                :
                                <div>no record found</div>
                        }
                    </div>
                    :
                    ""

            }

        </div>
    );
};

export default Search;