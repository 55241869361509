import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import picture from '../../images/fservice-3.jpg';

const MultipleImages = ({ ...props }) => {

  const { keyfeature2, category } = props;

  const [backgroundImage, setBackgroundImage] = useState(`url(${localStorage.getItem("cdn") + keyfeature2[0].image})`);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index, image) => {
    setBackgroundImage(`url(${image})`);
    setHoveredIndex(index); // Set the current index being hovered
  };

  const handleMouseLeave = () => {
    // setBackgroundImage(`url(${localStorage.getItem("cdn") + keyfeature2[0].image})`); // Revert to the initial image
    setBackgroundImage(`url(${picture})`); // Revert to the initial image
    setHoveredIndex(null); // Reset hovered index
  };

  return (
    <div className='mt-80'>
      <div className="space-30  d-md-block"></div>
      <div className="space-30  d-md-block"></div>
      <div className="space-30  d-md-block"></div>


      <section id="services" style={{ backgroundImage: `url('${picture}')`, transition: 'background-image 0.5s ease-in-out', backgroundSize: 'cover', backgroundPosition: 'center' }}>


        <div className="container-fluid px-0">
          <div className="row mx-0">
            <div className="features-service-wrapper">
              {keyfeature2.map((service, index) => (
                <NavLink className=" text-decoration-none" to={`/blogdetail/${service.slug}?q=${category}`}>
                <div
                  key={index}
                  className="features-service-item"
                  onMouseEnter={() => handleMouseEnter(index, localStorage.getItem("cdn") + service.image)}
                  onMouseLeave={handleMouseLeave}
                >
                  <figure className="features-service-img my-0">
                    <img src={service.image} alt={service.altText} />
                  </figure>
                  
                  <div className="features-service-content">
                    <div className="features-service-desc">
                      {/* Conditionally render based on hoveredIndex */}
                      {hoveredIndex == index && (
                        <>
                          {/* <div className="features-service-number">{"0" + (index + 1)}</div> */}
                          <h1>{service.title}</h1>
                        </>
                      )}

                      <p dangerouslySetInnerHTML={{ __html: service.description }} />

                    </div>
                    {hoveredIndex !== index && (
                      <>
                        {/* <div className="features-service-number">{"0" + (index + 1)}</div> */}
                        <h1 >{service.title}</h1>
                      </>
                    )}
                    <div className="features-service-overlay"></div>
                  </div>
              
                </div>
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>

  );
};

export default MultipleImages;