import React from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

const Apis = () => {
  var cookies = new Cookies();
  axios.defaults.baseURL = "https://admin.shopersbay.com/";

  axios.defaults.headers.common['authentication'] = "21e7ac68af78a3be93bbf71958f216d9"; //shopersbay

  const getMasterDataService = async () => {
    const response = await axios.get("asapi/getMasterData")
    return response;
  };
  const gvt = async (input_data) => {
    const response = await axios.post("asapi/gvt", input_data,);
    return response;
  };
  const getNewsDatalist = async () => {
    const response = await axios.get("asapi/getHomePageNewsData");
    return response;
  }
  const getNavigationlist = async () => {
    const response = await axios.get("asapi/getNavigationList");
    return response;
  }

  const SearchProductService = async (input_data) => {
    const response = await axios.post("asapi/searchPost", input_data);
    return response;
  };

  const SearchResultService = async (input_data) => {
    const response = await axios.post("/asapi/searchPostResult", input_data);
    return response;
  };


  const getPostCategoryList = async () => {
    const response = await axios.get("asapi/getDocumentData");
    return response;
  }
  const getSubscriptionlist = async () => {
    const response = await axios.get("asapi/getSubscription");
    // const response = await axios.get("asapi/getSubscription");
    return response;
  }
  const getFaqListlist = async () => {
    const response = await axios.get("asapi/getFaqList");
    return response;
  }
  const getHomePagePostDataApi = async (input_data) => {
    const response = await axios.post("asapi/getHomePagePostData",input_data);
    return response;
  }

  const getSubcategoryPostdatalist = async (input_data) => {
    const response = await axios.post("asapi/getSubcategoryPostdata", input_data);
    return response;
  }
  const getdetaillist = async (input_data) => {
    const response = await axios.post("asapi/getPostsDetail", input_data);
    return response;
  }
  const saveContactFormdata = async (input_data) => {
    const response = await axios.post("asapi/saveContact", input_data);
    return response;
  }
  const saveNewsLetterdata = async (input_data) => {
    const response = await axios.post("asapi/saveNewsLetter", input_data);
    return response;
  }

  const getRelatedPostslist = async (input_data) => {
    const response = await axios.post("asapi/getRelatedPosts", input_data);
    return response;
  }

  const getVendorType = async () => {
    const response = await axios.get("asapi/getVendorType");
    return response;
  }

  const saveVendor = async (input_data) => {
    const response = await axios.post("/asapi/saveVendor", input_data);
    return response;
  }

  const checkEmail = async (input_data) => {
    const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/varifyMailandPhone", input_data);
    // const response = await axios.post("asapi/varifyMailandPhone", input_data);
    return response;
  };

  const saveCustomer = async (input_data) => {
    const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/register", input_data);
    // const response = await axios.post("asapi/register", input_data);
    return response;
  }

  const placeOrder = async (input_data) => {
    const response = await axios.post("asapi/placeOrder", input_data);
    return response;
  }

  const getStaticPage = async () => {
    const response = await axios.get("asapi/getStaticPageList");
    return response;
  }

  const verifyotpService = async (input_data) => {
    const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/varifyOtp_new", input_data);
    // const response = await axios.post("asapi/varifyOtp_new", input_data);
    return response;
  };

  const LoginService = async (input_data) => {
    const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/login", input_data);
    // const response = await axios.post("asapi/login", input_data);
    return response;
  };

  const UserService = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/userlogin", input_data);
    const response = await axios.post("asapi/login", input_data);
    return response;
  };

  const getPaymentMethodService = async (input_data) => {
    // const response = await axios.get("http://localhost/admin.apanashopee.in/asapi/getPaymentMethod", {
    const response = await axios.get("asapi/getPaymentMethod", {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const saveSubscription = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/saveSubscription", input_data, {
    const response = await axios.post("asapi/saveSubscription", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const updateCheckoutService = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/checkout", input_data, {
    const response = await axios.post("asapi/checkout", input_data, {
      headers: { authorization: cookies.get("jwt_token") }
    });
    return response;
  };

  const addpostflag = async (input_data) => {
    // const response = await axios.post("http://localhost/admin.apanashopee.in/asapi/addFlag", input_data);
    const response = await axios.post("asapi/addFlag", input_data);
    return response;
  };

  return {
    getNewsDatalist,
    getNavigationlist,
    getPostCategoryList: getPostCategoryList,
    gvt: gvt,
    getSubcategoryPostdatalist: getSubcategoryPostdatalist,
    getdetaillist: getdetaillist,
    getHomePagePostDataApi: getHomePagePostDataApi,
    getRelatedPostslist: getRelatedPostslist,
    SearchProductService:SearchProductService,
    SearchResultService:SearchResultService,
    getMasterDataService: getMasterDataService,
    getVendorType: getVendorType,
    saveVendor: saveVendor,
    getSubscriptionlist: getSubscriptionlist,
    getFaqListlist: getFaqListlist,
    placeOrder: placeOrder,
    saveCustomer: saveCustomer,
    saveContactFormdata: saveContactFormdata,
    saveNewsLetterdata: saveNewsLetterdata,
    getStaticPage : getStaticPage,
    checkEmail : checkEmail,
    verifyotpService : verifyotpService,
    LoginService : LoginService,
    getPaymentMethodService : getPaymentMethodService,
    saveSubscription:saveSubscription,
    updateCheckoutService:updateCheckoutService,
    UserService:UserService,
    addpostflag:addpostflag,
  };
};
export default Apis;