import React from 'react'
import { IoIosArrowForward } from "react-icons/io";
import {NavLink} from 'react-router-dom'
import bg from '../../images/desktop_background_img.webp'

const DetailBanner = ({data}) => {
  return (
    <div id="content" className="site-content">
        <div className="page-header flex-middle page-header-service" style={{backgroundImage: `url(${bg})`}}>
            <div className="container">
                <div className="inner flex-middle">
                    <h1 className="page-title">{data.hasOwnProperty("title") && data.title}</h1>
                    <ul id="breadcrumbs" className="breadcrumbs none-style">
                        <li><NavLink className="text-decoration-none" to="/">Home</NavLink><IoIosArrowForward /></li>
                        <li className="active">{data.hasOwnProperty("title") && data.title}</li>
                    </ul>    
                </div>
            </div>
        </div>
    </div>
  )
}

export default DetailBanner
