import React, { useContext, useEffect, useState } from 'react';
import { HiChevronRight } from 'react-icons/hi'
import { BiArrowBack } from "react-icons/bi"
import { GrFormAdd, GrFormSubtract } from "react-icons/gr"
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import logo from '../../images/bg_image.webp';
const AllCategories = () => {

    const categorydata = useSelector(state => state.masterdata.staticpage);
    const categoryOpen = useSelector(state => state.masterdata.categoryOpen);
    const presntcategoryOpen = useSelector(state => state.masterdata.presntcategoryOpen);
    const presntsubcategoryOpen = useSelector(state => state.masterdata.presntsubcategoryOpen);
    const sidebar = useSelector(state => state.masterdata.sidebar);

    const dispatch = useDispatch()

    const navigate = useNavigate();
    const handleClick = (id) => {
        dispatch({ type: 'setcategoryOpen', payload: "level2" })
        dispatch({ type: 'setpresentcategoryOpen', payload: id })
    }

    const handlecategory = (url) => {
        showSidebar()
        navigate(`/pages/docs/${url}`)
    }
    const handlesubcategory = (id, url) => {
        navigate(`/subcategory/${url}`)
        showSidebar()
    }
    const handlesubcategoryClick = (id) => {
        if (presntsubcategoryOpen == id) {
            dispatch({ type: 'setpresentsubcategoryOpen', payload: 0 })
        } else {
            dispatch({ type: 'setpresentsubcategoryOpen', payload: id })
        }
        dispatch({ type: 'setcategoryOpen', payload: "level2" })
    }

    const showSidebar = () => {
        dispatch({ type: 'setsidebar', payload: !sidebar })
    };

    return (
        <div className='main_category'>
            {/* {
                categoryOpen == 'level1' &&
                <div className='main_category-header'><div><BiArrowBack color="black" size="25" style={{ marginTop: "-3px", cursor: "pointer" }} onClick={() => setcategoryOpen("")} /> <span>Main Menu</span></div></div>
            } */}
            {
                categoryOpen == 'level1' && categorydata.map(ele => {
                    return (
                        <div className='category' key={ele.id}>
                            <div className='category-leftside' >
                                <h5 className='category-leftsidetitle'>{ele.category_name}</h5>
                                {
                                    ele.posts.length > 0 &&
                                    ele.posts.map(ele1 => {
                                        return (
                                            <NavLink to={`/pages/docs/${ele1.slug}`} className={"text-decoration-none"} onClick={() => handlecategory(ele.slug)}>
                                                <h5 className='post-leftsidetitle'>{ele1.title}</h5>
                                            </NavLink>
                                        )
                                    })
                                }
                            </div>
                            {
                                ele.child.length > 0 &&
                                <div className='category-rightside' onClick={() => handleClick(ele.id, ele.slug)}>
                                    <div className='category-rightside-icon'><HiChevronRight color="black" size="25" /></div>
                                </div>
                            }
                        </div>
                    )
                })
            }
            {/* {
                categoryOpen == 'level2' &&
                <div className='main_category-header'><div><BiArrowBack color="black" size="25" style={{ marginTop: "-3px", cursor: "pointer" }} onClick={() => dispatch({ type: 'setcategoryOpen', payload: "level1" })} /> <span>{categorydata.filter((ele1) => ele1.id == presntcategoryOpen)[0].name}</span></div></div>
            } */}

            {/* {
                (categoryOpen == 'level2' && categorydata.filter((ele1) => ele1.id == presntcategoryOpen)[0].childCategory.map(ele => {
                    return (
                        <>
                            <div className='category' onClick={() => handlesubcategoryClick(ele.id, ele.slug)}>
                                <div className='category-leftside' onClick={() => handlesubcategory(ele.id, ele.slug)}>
                                    <div className='category-leftsideimg'>
                                        <img

                                            referrerPolicy="no-referrer"

                                            src={localStorage.getItem("cdn") + ele.image}
                                            onError={e => {
                                                // e.currentTarget.src = logo
                                            }} />
                                    </div>
                                    <h5 className='category-leftsidetitle'>{ele.name}</h5>
                                </div>
                                {
                                    ele.childCategory.length > 0 &&
                                    <div className='category-rightside' onClick={() => handlesubcategoryClick(ele.id, ele.slug)}>
                                        <div className='category-rightside-icon'>
                                            {
                                                presntsubcategoryOpen == ele.id ?
                                                    <GrFormSubtract color="black" size="25" />
                                                    :
                                                    <GrFormAdd color="black" size="25" />

                                            }
                                        </div>
                                    </div>
                                }


                            </div>
                            {
                                (presntsubcategoryOpen == ele.id) && ele.childCategory
                                    .map(sub_sub => {
                                        return (
                                            <NavLink to={`/subcategory/${sub_sub.slug}`} onClick={showSidebar} className="sidebar-subcategory category text-decoration-none">
                                                <div className='category-leftside'>
                                                    <div className='category-leftsideimg'>
                                                        <img
                                                            referrerPolicy="no-referrer"
                                                            src={localStorage.getItem("cdn") + sub_sub.image}
                                                            onError={e => {
                                                                // e.currentTarget.src = logo
                                                            }} />
                                                    </div>
                                                    <h5 className='category-leftsidetitle'>{sub_sub.name}</h5>
                                                </div>
                                            </NavLink>
                                        )
                                    })
                            }
                        </>

                    )
                }))
            } */}

        </div>
    );
};

export default AllCategories;