import React from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
const Card = ({...props}) => {
    const { Card, category } = props;

    return (
        <Container>
            <div class="row icon-box-main mx-0">
                {
                    Card.map(ele => {
                        return (
                            <div  className="col-lg-4 col-md-6">
                            <NavLink className="icon-box text-decoration-none" to={`/blogdetail/${ele.slug}?q=${category}`}>
                                <div>

                                    {/* <div className="icon-main">
                                        <img className="icon-image-maim" src={localStorage.getItem("cdn") + ele.image} />
                                    </div> */}
                                    <div className="content-box">
                                        <h3 className="title-box">{ele.title}</h3>
                                        <p dangerouslySetInnerHTML={{ __html: ele.description }} />
                                    </div>


                                </div>
                            </NavLink>
                            </div>
                        )
                    })
                }


            </div>
        </Container>
    )
}
export default Card;