import React, { useContext, useEffect } from 'react';
import { DocHeader, Footer, Loader, ReactToast } from '../common';
import { CatSidebar } from '../../views';
import { Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { Row, Col } from "react-bootstrap"
const DocLayout = ({ children }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const dochomedata = useSelector(state => state.masterdata.dochomedata);
    const homedata = useSelector(state => state.masterdata.homedata);
    return (
        <>

            <DocHeader />
            <CatSidebar />
            <div className='mt-90'>
                {
                    // !isMobile ?


                    children


                    // : 
                    // <div>

                    // </div>
                }
                {/* {
                    dochomedata ?
                    <Container fluid>
                        <Row>
                            <Col lg={2}></Col>
                            <Col lg={10}>
                                {
                                    homedata && homedata.postcategorydataList &&
                                    homedata.postcategorydataList["DOCHome"] &&
                                    <div dangerouslySetInnerHTML={{ __html: homedata.postcategorydataList["DOCHome"][0].description }} />


                                }
                            </Col>
                        </Row>
                    </Container>
                    :
                    <div className='custom-height'></div>
                } */}

            </div>
            <ReactToast />


            {
                // !isMobile &&
                // <Footer/>
            }
            <Loader/>

        </>
    );
};

export default DocLayout;