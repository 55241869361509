import React from 'react'
import pic from '../../images/contactus_img.png'
import wallpaper from '../../images/contactus_bg.png'
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { SiMinutemailer } from "react-icons/si";
import {NavLink} from 'react-router-dom'

const ContactComponent = () => {
  return (
    <div className="contact-page" style={{backgroundImage: `url(${wallpaper})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-6 mb-5 mb-xl-0">
                        <div className="ot-heading">
                            <span className="is_highlight">contact details</span>
                            <h3 className="main-head">Our Contacts</h3>
                        </div>
                        <div className="space-20"></div>
                        <div className="space-2"></div>
                        <p>Give us a call or drop an email, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions.</p>
                        <div className="space-10"></div>
                        <div className="box-s1 icon-left">
                            <div className="icon-box icon-box-2 contact-iconb">
                                <div className="icon-main">
                                    <FaMapMarkerAlt size={25} className='icon'/>
                                </div>
                                <div className="content-box">
                                    <h6 className="title-box">Visit Us Daily</h6>
                                    <p>{localStorage.getItem("address")}</p>
                                </div>
                            </div>
                            <div className="space-30"></div>
                            <div className="icon-box icon-box-2 contact-iconb">
                                <div className="icon-main contact-icon-phone">
                                    <FaPhoneAlt size={25} className='icon'/>
                                </div>
                                <div className="content-box">
                                    <h6 className="title-box"><NavLink to={`tel:+91-${localStorage.getItem("vendor_contact")}`}>Call Us 24/7</NavLink></h6>
                                    <p>+91-{localStorage.getItem("vendor_contact")}</p>
                                </div>
                            </div>
                            <div className="space-30"></div>
                            <div className="icon-box icon-box-2 contact-iconb">
                                <div className="icon-main contact-icon-mail">
                                    <SiMinutemailer size={25} className='icon'/>
                                </div>
                                <div className="content-box">
                                    <h6 className="title-box"><NavLink to={`mailto:${localStorage.getItem("vendor_email")}`}>Mail Us</NavLink></h6>
                                    <p>{localStorage.getItem("vendor_email")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className="contact-img">
                            <img src={pic} alt="" className='img-fluid'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ContactComponent
