import React, { useContext, useEffect } from 'react';
import { Header, Footer, Loader, ReactToast } from '../common';

import { Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
const DesktopLayout = ({ children }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });

    return (
        <>

            <Header />
            <div className='mt-90'>
                {
                    children
                }
            </div>
            <ReactToast />
            {
                // !isMobile &&
                <Footer />
            }
            <Loader />

        </>
    );
};

export default DesktopLayout;