import React from 'react'
import Contactformaddress from './Contactformaddress';

const ContactUs = () => {
  return (
    <>
       <div className="contact-form">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <Contactformaddress />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ContactUs
