import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from "react-bootstrap"
import { FaHome } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DetailAction from "../redux/actions/DetailAction";
import MasterAction from '../redux/actions/MasterAction';
import { Loader } from '../components/common';
const Detail = ({ homedata }) => {
  const { getdetaillistdata } = DetailAction()
  const {addFlagApi} = MasterAction()
  const deataidata = useSelector(state => state.masterdata.deataidata);
  // alert()
  const categorydata = useSelector(state => state.masterdata.staticpage);
  const [detail, setdetail] = useState([]);
  const { slug } = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    if (categorydata.length > 0) {
      const result = categorydata
        .map((category) => category.posts)
        .flat()
        .find((posts) => posts.slug === slug);

      if (result == undefined) {
        const result1 = categorydata.filter((category) => category.child.length > 0);
        if (result1.length > 0) {
          setdetail(result1.map(ele2 => {
            return ele2.child.map(ele => {
              var io = ele.posts.filter(ele9 => ele9.slug == slug);
              if (io.length > 0) {
                return ele.posts.filter(ele9 => ele9.slug == slug)[0]
              }
            }).filter(ekre => ekre != undefined)[0]
          }).filter(ekre => ekre != undefined));
        } else {

        }
      } else {
        setdetail([result])
      }


    }
  }, [categorydata, slug])

  const addFlag = (count) => {
    var inputdata={
      "flag"  : count,
      "id"    : detail[0].postid
    }
    // console.log("count", inputdata)
    dispatch(addFlagApi(inputdata))
  }


  return (
    <>
    {
      detail.length > 0 ?
      <Container fluid>
        <Row>
          <Col lg={2} md={12} sm={12}></Col>
          <Col lg={10} md={12} sm={12} className='document-deatil'>
            <div >
              <div dangerouslySetInnerHTML={{ __html: detail[0].body }} />
              <div className='docs_review_border mt-5 mb-4'>
                <div className='docs_review'>
                  <p> Was this page helpful ? </p>
                  <div className='d-flex docs_review_btn_green'>
                    <div className='a' onClick={()=>addFlag(1)}>Yes</div>
                    <div className='b' onClick={()=>addFlag(0)}>No</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      :
      <Loader />
}
    </>
  )
}
export default Detail;
